.navigation__menu-item {
  margin: 0 var(--margin-s);
  cursor: pointer;
  border-bottom: 2px solid transparent;
  outline: none;
  text-decoration: none;
  padding: 0.25em;
  color: var(--navigation-link-color);
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.5s ease, color 0.5s ease;
  font-size: 0.9em;
}

.navigation__menu-item:hover, .navigation__menu-item.active {
  border-bottom: 2px solid var(--color-hover);
  color: var(--color-hover);
  transition: border-bottom 0.5s ease, color 0.5s ease;
}

.navigation {
  padding: 0.5em;
  justify-content: space-evenly;
  background: var(--navigation-background-color);
}

.navigation a {
  text-decoration: none;
  outline: none;
}
