.col {
  display: flex;
  flex-flow: column;
}

.flex {
  display: flex;
}

.row {
  display: flex;
  flex-flow: row;
}

.clickable {
  cursor: pointer;
}

.hide {
  display: none;
}

.space-between {
  justify-content: space-between !important;
}

.space-evenly {
  justify-content: space-evenly;
}

.space-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.wrap {
  flex-wrap: wrap;
}

.wrap-reverse {
  flex-wrap: wrap-reverse;
}

.fit-content {
  width: fit-content;
}

.text-align-left {
  text-align: left;
}

.full-width {
  width: 100%;
}
