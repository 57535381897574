.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 80px !important;
  width: 40px !important;
}

.image-gallery-icon:hover {
  color: var(--color-title) !important;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover {
  border: 3px solid var(--color-title) !important;
}

.gallery-wrapper {
  margin: auto;
  min-height: 450px;
}

.gallery-loader {
  height: 480px;
  width: 600px;
  margin: auto;
}

@media screen and (min-width: 650px) {
  .gallery-wrapper {
    max-width: 600px;
    min-height: 450px;
  }
}

@media screen and (min-width: 550px) and (max-width: 650px) {
  .gallery-wrapper {
    max-width: 500px;
    min-height: 400px;
  }
}

@media screen and (min-width: 450px) and (max-width: 550px) {
  .gallery-wrapper {
    max-width: 400px;
    min-height: 330px;
  }
}

@media screen and (max-width: 450px) {
  .gallery-wrapper {
    max-width: 300px;
    min-height: 270px;
  }
}
