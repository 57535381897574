.map-col, .info-col {
  width: 49%;
}

@media screen and (max-width: 800px) {
  .map-col, .info-col {
    width: 100%;
  }
  .contact-container {
    flex-flow: column-reverse;
  }
}

.map {
  width: 100%;
  height: 450px;
  border: none;
}

.form-col {
  max-width: 450px;
  width: 100%;
}
