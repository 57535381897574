html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Raleway', sans-serif;
  color: var(--color-font);
  background: var(--color-background);
  line-height: 1.35;
  font-size: 0.95em;
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  color: var(--color-title);
}

.global {
  display: flex;
  width: 100%;
  margin: auto;
  height: 100%;
}

a {
  color: var(--color-font);
  transition: 0.2s ease all;
  text-decoration: none;
}

a:hover {
  color: var(--color-hover);
}
