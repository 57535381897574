.activity-item-text-container {
    width: 350px;
}

.activity-distance-value {
  color: gray;
  font-weight: bold;
}

@media screen and (max-width: 450px) {
    .activity-item-text-container {
        width: 100%;
    }
}
