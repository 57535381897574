@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,400;0,700;1,400&display=swap');
@import "./styles/globals.css";
@import "./styles/margin.css";
@import "./styles/default-classes.css";
@import "./styles/common-layout.css";
@import "~react-image-gallery/styles/css/image-gallery.css";
/* index page */
ul.check-list {
  list-style-type: '✔️ ';
}

