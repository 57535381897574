.main-container {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;
  flex: 1;
}

.main-container__wrapper {
  height: 100%;
  margin: auto;
  width: 100%;
  max-width: 960px;
}

.content {
  padding: 0.5em 1em 1em 1em;
  flex: 1;
  height: max-content;
  text-align: justify;
}
.about {
  margin-left: 1em;
}

@media screen and (max-width: 960px) {
  .main-container__wrapper > .row {
    flex-flow: column;
  }

  .about {
    margin-top: var(--margin-m);
    margin-left: 0 !important;
    justify-content: space-evenly;
    display: flex;
  }

  .about.col {
    flex-flow: row;
    flex-wrap: wrap;
  }
}
