.banner h1 {
  font-weight: normal;
  font-size: 2.7em;
  margin-left: 0.25em;
  font-weight: bold;
}

@media screen and (max-width: 501px) {
  .banner h1 {
    font-size: 2.2em;
  }
}

.logo-gite {
  max-height: 85px;
}

.sub-title {
  margin-top: 0.15em;
  font-size: 1em;
  margin-left: 1.5em;
  font-weight: bold;
  color: var(--navigation-link-color);
}

.sub-title > span > a {
  color: var(--navigation-link-color);
}

.sub-title > span > a:hover {
  color: var(--color-hover);
}

.banner h1>a {
  color: inherit;
  text-decoration: none;
}
