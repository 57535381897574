.information-modal {
  border: 1px solid var(--navigation-background-color);
  border-radius: 5px;
}

.information-modal-warning {
  border: 1px solid var(--color-secondary);
  color: var(--color-secondary);
  background: var(--color-secondary-ultra-light);
}

.information-modal-warning h3 {
  color: var(--color-secondary) !important;
}

.information-modal__icon {
  width: 50px;
}
