.home-image {
  max-width: 100%;
}

.mobile_only {
    display: none;
}

.desktop_only {
    display: flex;
}

@media screen and (max-width: 789px) {
    .home__col {
        width: 100%;
    }

    .home__switchable-row {
        flex-flow: column;
    }

    .mobile_only {
        display: flex !important;
    }
    .desktop_only {
        display: none !important;
    }
}

@media screen and (min-width: 790px) {
    .home__col {
        width: 50%;
    }
}
