.contact-form-input {
  min-width: 300px;
  border: 2px solid lightgray;
  border-radius: 5px;
  padding: 2px 10px;
  transition: 0.5s ease all;
}

input.contact-form-input {
  height: 25px;
}

.contact-form-input:hover {
  border-color: var(--navigation-link-color);
}

.contact-form-input:focus {
  border-color: var(--color-hover);
}

.contact-form-button {
  min-width: 300px;
  border: 2px solid lightgray;
  color: var(--color-primary-light);
  border-radius: 5px;
  background: var(--color-primary-ultra-light);
  padding: 8px 10px;
  transition: 1s ease all;
  cursor: pointer;
  font-size: 1.1em;
}
.contact-form-button:disabled {
  border-color: lightgray !important;
  background: var(--color-primary-ultra-light) !important;
  color: var(--color-primary-light) !important;
}

.contact-form-button:hover {
  border-color: var(--color-hover);
  background: var(--color-primary-lightest);
  color: var(--color-hover);
}

.error-line {
  color: red;
}

.success-contact-info {
  color: green;
}
