.icon-color-primary {
  color: var(--color-primary);
}

.icon-color-accent {
  color: var(--color-accent);
}

.icon-color-secondary {
  color: var(--color-secondary);
}

.icon-size-xs {
  font-size: 15px !important;
}
.icon-size-sm {
  font-size: 20px !important;
}
.icon-size-m {
  font-size: 25px !important;
}
.icon-size-l {
  font-size: 30px !important;
}
.icon-size-xl {
  font-size: 35px !important;
}

.animate {
  -webkit-animation: animate 1s infinite;
  -moz-animation: animate 1s infinite;
  -ms-animation: animate 1s infinite;
  animation: animate 1s infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  animation-timing-function: linear
}

@keyframes animate {
  100% {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}
