.footer {
  padding: 1em;
  justify-content: center;
}

.footer.row {
  flex-flow: row !important;
}

.copyright {
  font-size: 0.8em;
  color: var(--color-primary);
}

.copyright a {
  text-decoration: none;
  color: var(--color-primary);
}
