.partner-card {
  width: 75px;
  height: 100px;
}

.partner__link-img {
  max-height: 75px;
  max-width: 90px;
}
.partner-item__image-container {
  width: 75px;
  height: 90px;
  margin: auto;
}
