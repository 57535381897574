:root {
  --color-font: #030B15;
  --color-background: #f0f3f7;
  --color-primary: #152130;
  --color-secondary: #ef9b00;
  --color-accent: #FF6400;
  --color-title: #346ca5;
  --color-hover: #0E51A7;

  --color-primary-light: rgba(21, 33, 48, 0.7);
  --color-primary-lighter: rgba(21, 33, 48, 0.5);
  --color-primary-lightest: rgba(21, 33, 48, 0.15);
  --color-primary-ultra-light: rgba(21, 33, 48, 0.05);

  --color-secondary-light: rgba(239, 155, 0, 0.7);
  --color-secondary-lighter: rgba(239, 155, 0, 0.5);
  --color-secondary-lightest: rgba(239, 155, 0, 0.25);
  --color-secondary-ultra-light: rgba(239, 155, 0, 0.05);

  --navigation-background-color: rgba(60, 73, 91, 0.2);
  --navigation-link-color: #346ca5;

  --margin-xs: 0.25em;
  --margin-s: 0.5em;
  --margin-m: 1em;
  --margin-l: 1.5em;
}
